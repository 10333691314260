import axios from 'axios'

const axiosApi = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API
})

export const setAuthHeader = (accessToken) => {
  axiosApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
}

setAuthHeader(localStorage.getItem('accessToken'))

export default axiosApi;
