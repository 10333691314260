import axios from "@/axios.js"

export default {
  fetchFiles({commit}, type) {
    return new Promise((resolve, reject) => {
      axios.get(`/contracts/files/${type}`)
        .then((response) => {
          commit('GET_FILES', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadFile({commit}, payload) {
    commit('ADD_FILE', JSON.parse(payload.currentTarget.response));
  },
  deleteFile({commit}, fileId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/contracts/files/${fileId}`)
        .then((response) => {
          commit('DELETE_FILE', fileId)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
