import axios from "@/axios.js"

export default {
  fetchContract({commit}, contractId) {
    return new Promise((resolve, reject) => {
      axios.get(`/contracts/contract-details/${contractId}`)
        .then((response) => {
          commit('GET_CONTRACT', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateImplantation({commit}, implantation) {
    return new Promise((resolve, reject) => {
      axios.put("/contracts/implantation/update", implantation)
        .then((response) => {
          commit('UPDATE_IMPLANTATION', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  },
  updateInvestigation({commit}, investigation) {
    return new Promise((resolve, reject) => {
      axios.put("/contracts/investigation/update", investigation)
        .then((response) => {
          commit('UPDATE_INVESTIGATION', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateFixedRemuneration({commit}, investigation) {
    return new Promise((resolve, reject) => {
      axios.put("/contracts/fixed-remuneration/update", investigation)
        .then((response) => {
          commit('UPDATE_FIXED_REMUNERATION', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
