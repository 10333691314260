import axios from "@/axios.js"

export default {
  addUser({commit}, user) {
    return new Promise((resolve, reject) => {
      axios.post("/users/create", user)
        .then((response) => {
          commit('ADD_USER', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchUsers({commit}) {
    return new Promise((resolve, reject) => {
      axios.get("/users")
        .then((response) => {
          commit('GET_USERS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateUser({commit}, user) {
    return new Promise((resolve, reject) => {
      axios.put(`/users/${user.id}/update`, user)
        .then((response) => {
          commit('UPDATE_USER', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateRole({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`/user/updateRole`, payload)
        .then((response) => {
          commit('UPDATE_ROLE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
