const actions = {
  updateVerticalNavMenuWidth({commit}, width) {
    commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
  },
  updateStarredPage({commit}, payload) {
    commit('UPDATE_STARRED_PAGE', payload)
  },
  arrangeStarredPagesLimited({commit}, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list)
  },
  arrangeStarredPagesMore({commit}, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list)
  },
  updateTheme({commit}, val) {
    commit('UPDATE_THEME', val)
  },
}

export default actions
