import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

import moduleAuth from './auth/moduleAuth.js'
import dataList from './data-list/moduleDataList'
import moduleContractDetails from './contract-details/moduleContractDetails';
import moduleFiles from './files/moduleFiles'
import moduleUsers from './users/moduleUsers'

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
      auth: moduleAuth,
      dataList,
      moduleContractDetails,
      moduleFiles,
      moduleUsers,
    },
    strict: process.env.NODE_ENV !== 'production'
})
