export default {
  GET_FILES(state, files) {
    state.files = files
  },
  ADD_FILE(state, files) {
    files.forEach((file) => {
      state.files.unshift(file)
    })
  },
  DELETE_FILE(state, fileId) {
    const fileIndex = state.files.findIndex((file) => file.id === fileId)
    state.files.splice(fileIndex, 1)
  }
}
