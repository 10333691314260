export default {
  GET_CONTRACT(state, contract) {
    state.contract = contract
  },
  UPDATE_IMPLANTATION(state, contract) {
    Object.assign(state.contract, contract)
  },
  UPDATE_INVESTIGATION(state, contract) {
    Object.assign(state.contract, contract)
  },
  UPDATE_FIXED_REMUNERATION(state, contract) {
    Object.assign(state.contract, contract)
  }
}
