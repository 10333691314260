import axios from "../../../axios/index.js"
import store from "../../../../store/store.js"

// Token Refresh
let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched(accessToken) {
  subscribers = subscribers.filter(callback => callback(accessToken))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

export default {
  init() {
    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      const { config, response: { status } } = error
      const originalRequest = config

      if (status === 401) {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true
          store.dispatch("auth/fetchAccessToken")
            .then((accessToken) => {
              isAlreadyFetchingAccessToken = false
              onAccessTokenFetched(accessToken)
            })
        }

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber(accessToken => {

            originalRequest.headers.Authorization = 'Bearer ' + JSON.parse(accessToken.config.data).accessToken
            resolve(axios(originalRequest))
          })
        })
        return retryOriginalRequest
      }
      return Promise.reject(error)
    })
  },
  login(username, pwd) {
    return axios.post("/auth/login", {username: username, password: pwd})
  },
  registerUser(name, email, pwd) {
    return axios.post("/api/auth/register", {displayName: name, email: email, password: pwd})
  },
  refreshToken() {
      return axios.post("/refreshToken", {accessToken: localStorage.getItem("accessToken")})
  }
}
