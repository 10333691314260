import jwt from "../../http/requests/auth/jwt/index.js"
import router from '@/router'
import axios from "@/axios.js"

export default {

  loginJWT({commit}, payload) {
    return new Promise((resolve, reject) => {
      jwt.login(payload.userDetails.username, payload.userDetails.password)
        .then(response => {
          if (response.data) {
            localStorage.setItem("accessToken", response.data.accessToken)

            payload.aclChangeRole(response.data.userRole)

            commit('UPDATE_USER_INFO', response.data, {root: true})

            commit("SET_BEARER", response.data.accessToken)

            router.push({path: '/'});

            resolve(response)
          } else {
            reject({message: "Wrong Email or Password"})
          }

        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchAccessToken() {
    return new Promise((resolve) => {
      jwt.refreshToken().then(response => {
        resolve(response)
      })
    })
  },

  // eslint-disable-next-line no-unused-vars
  updatePassword({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/users/updatePassword`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  },
}
