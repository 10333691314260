import EventEmitter from 'events'

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

class AuthService extends EventEmitter {

  isAuthenticated() {
    return userInfo
  }
}

export default new AuthService();
