import state from './moduleFilesState.js'
import mutations from './moduleFilesMutations.js'
import actions from './moduleFilesActions.js'
import getters from './moduleFilesGetters.js'

export default {
  // isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
