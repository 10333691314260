import axios from "@/axios.js"

export default {
  addItem({commit}, contract) {
    return new Promise((resolve, reject) => {
      axios.post("/contracts", contract)
        .then((response) => {
          commit('ADD_CONTRACT', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDataListItems({commit}) {
    return new Promise((resolve, reject) => {
      axios.get("/contracts")
        .then((response) => {
          commit('GET_CONTRACTS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateItem({commit}, contract) {
    return new Promise((resolve, reject) => {
      axios.put(`/contracts/${contract.id}`, contract)
        .then((response) => {
          commit('UPDATE_CONTRACT', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updatePhase({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`/contracts/updatePhase`, payload)
        .then((response) => {
          commit('UPDATE_PHASE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addImplantation({commit}, implantation) {
    return new Promise((resolve, reject) => {
      axios.post("/contracts/implantation/create", implantation)
        .then((response) => {
          commit('ADD_IMPLANTATION', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  },
  addInvestigation({commit}, investigation) {
    return new Promise((resolve, reject) => {
      axios.post("/contracts/investigation/create", investigation)
        .then((response) => {
          commit('ADD_INVESTIGATION', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addFixedRemuneration({commit}, investigation) {
    return new Promise((resolve, reject) => {
      axios.post("/contracts/fixed-remuneration/create", investigation)
        .then((response) => {
          commit('ADD_FIXED_REMUNERATION', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
