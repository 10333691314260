export default {
  ADD_CONTRACT(state, payload) {
    payload.countImplantations = 0
    payload.countInvestigations = 0
    payload.totalImplantations = null
    payload.totalInvestigations = null
    state.contracts.unshift(payload)
  },
  GET_CONTRACTS(state, contracts) {
    state.contracts = contracts
  },
  UPDATE_CONTRACT(state, payload) {
    const contractIndex = state.contracts.findIndex((p) => p.id === payload.id)
    Object.assign(state.contracts[contractIndex], payload)
  },
  UPDATE_PHASE(state, payload) {
    const contractIndex = state.contracts.findIndex((p) => p.id === payload.id)
    Object.assign(state.contracts[contractIndex], payload)
  },
  ADD_IMPLANTATION(state, payload) {
    const contractIndex = state.contracts.findIndex((p) => p.id === payload.id)
    Object.assign(state.contracts[contractIndex], payload)
  },
  ADD_INVESTIGATION(state, payload) {
    const contractIndex = state.contracts.findIndex((p) => p.id === payload.id)
    Object.assign(state.contracts[contractIndex], payload)
  },
  ADD_FIXED_REMUNERATION(state, payload) {
    const contractIndex = state.contracts.findIndex((p) => p.id === payload.id)
    Object.assign(state.contracts[contractIndex], payload)
  },
}
