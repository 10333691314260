export default {
  ADD_USER(state, payload) {
    state.users.unshift(payload)
  },
  GET_USERS(state, users) {
    state.users = users
  },
  UPDATE_USER(state, payload) {
    const userIndex = state.users.findIndex((p) => p.id === payload.id)
    Object.assign(state.users[userIndex], payload)
  },
  UPDATE_ROLE(state, payload) {
    const userIndex = state.users.findIndex((p) => p.id === payload.id)
    Object.assign(state.users[userIndex], payload)
  },
}
