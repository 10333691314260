import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes: [

    {
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        {
          path: '/',
          name: 'contracts',
          component: () => import('./views/Contracts.vue'),
          meta: {
            breadcrumb: [
              {title: 'Início', url: '/'},
              {title: 'Contratos', active: true},
            ],
            pageTitle: 'Cronograma de Contratos',
            requiresAuth: true,
            rule: 'user'
          },
        },
        {
          path: '/contractDetails/:contractId',
          name: 'contractDetails',
          component: () => import('./views/ContractDetails.vue'),
          meta: {
            breadcrumb: [
              {title: 'Início', url: '/'},
              {title: 'Contratos', url: '/'},
              {title: 'Detalhes do Contrato', active: true},
            ],
            pageTitle: 'Detalhes do Contrato',
            requiresAuth: true,
            rule: 'user'
          },
        },
        {
          path: '/files/releases',
          name: 'filesReleases',
          component: () => import('./views/Files/Releases.vue'),
          meta: {
            breadcrumb: [
              {title: 'Início', url: '/'},
              {title: 'Arquivos'},
              {title: 'Listar', active: true},
            ],
            pageTitle: 'Arquivos',
            requiresAuth: true,
            rule: 'user'
          },
        },
        {
          path: '/files/notice',
          name: 'filesNotice',
          component: () => import('./views/Files/NoticeFiles.vue'),
          meta: {
            breadcrumb: [
              {title: 'Início', url: '/'},
              {title: 'Arquivos'},
              {title: 'Listar', active: true},
            ],
            pageTitle: 'Arquivos',
            requiresAuth: true,
            rule: 'user'
          },
        },
        {
          path: '/files/scope',
          name: 'filesScope',
          component: () => import('./views/Files/ScopeFiles'),
          meta: {
            breadcrumb: [
              {title: 'Início', url: '/'},
              {title: 'Arquivos'},
              {title: 'Listar', active: true},
            ],
            pageTitle: 'Arquivos',
            requiresAuth: true,
            rule: 'user'
          },
        },
        {
          path: '/files/thematicMaps',
          name: 'filesThematicMaps',
          component: () => import('./views/Files/ThematicMapsFiles'),
          meta: {
            breadcrumb: [
              {title: 'Início', url: '/'},
              {title: 'Arquivos'},
              {title: 'Listar', active: true},
            ],
            pageTitle: 'Arquivos',
            requiresAuth: true,
            rule: 'user'
          },
        },
        {
          path: '/files/implantedPhysics',
          name: 'filesImplantedPhysics',
          component: () => import('./views/Files/ImplantedPhysicsFiles'),
          meta: {
            breadcrumb: [
              {title: 'Início', url: '/'},
              {title: 'Arquivos'},
              {title: 'Listar', active: true},
            ],
            pageTitle: 'Arquivos',
            requiresAuth: true,
            rule: 'user'
          },
        },
        {
          path: '/files/criticalAnalysis',
          name: 'filesCriticalAnalysis',
          component: () => import('./views/Files/CriticalAnalysisFiles'),
          meta: {
            breadcrumb: [
              {title: 'Início', url: '/'},
              {title: 'Arquivos'},
              {title: 'Listar', active: true},
            ],
            pageTitle: 'Arquivos',
            requiresAuth: true,
            rule: 'user'
          },
        },
        {
          path: '/files/financial',
          name: 'filesFinancial',
          component: () => import('./views/Files/FinancialFiles'),
          meta: {
            breadcrumb: [
              {title: 'Início', url: '/'},
              {title: 'Arquivos'},
              {title: 'Listar', active: true},
            ],
            pageTitle: 'Arquivos',
            requiresAuth: true,
            rule: 'user'
          },
        },
        {
          path: '/users',
          name: 'users',
          component: () => import('./views/Users'),
          meta: {
            breadcrumb: [
              {title: 'Início', url: '/'},
              {title: 'Usuários'},
              {title: 'Listar', active: true},
            ],
            pageTitle: 'Usuários',
            requiresAuth: true,
            rule: 'admin'
          },
        },
      ],
    },
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            guest: true,
            rule: 'user'
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            guest: true,
            rule: 'user'
          }
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            guest: true,
            rule: 'user'
          }
        },
      ]
    },
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ],
});

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
})

router.beforeEach((to, from, next) => {
  const json = localStorage.getItem('userInfo')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const user = JSON.parse(json)
    user ? next() : next({path: '/login'})
  } else {
    next()
  }
})

export default router
