import state from './moduleContractDetailsState.js'
import mutations from './moduleContractDetailsMutations.js'
import actions from './moduleContractDetailsActions.js'
import getters from './moduleContractDetailsGetters.js'

export default {
  // isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
