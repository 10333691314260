import Vue from 'vue'
import App from './App.vue'
import acl from './acl/acl'
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css'
import 'vuesax/dist/vuesax.css';
import axios from "./axios.js"
import '../themeConfig.js'
import './globalComponents.js'
import './assets/scss/main.scss'
import '@/assets/css/main.css'
import router from './router'
import store from './store/store'
import i18n from './i18n/i18n'
import './filters/filters'
import {VueHammer} from 'vue2-hammer'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import VeeValidate from 'vee-validate';
import VueCurrencyInput from 'vue-currency-input'
import i18nData from "./i18n/i18nData";

require('./assets/css/iconfont.css')

Vue.prototype.$http = axios
Vue.use(Vuesax)
Vue.use(VueHammer)
Vue.use(VeeValidate, {
  locale: 'pt_BR',
  i18n,
  dictionary: i18nData
});

Vue.config.productionTip = false

const pluginOptions = {
  globalOptions: {currency: null, locale: 'pt-BR'}
}
Vue.use(VueCurrencyInput, pluginOptions)

new Vue({
  router,
  store,
  i18n,
  acl,
  render: h => h(App)
}).$mount('#app')
